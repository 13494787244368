export const eventsData = [
  [
    {
      name: 'Emblem of Steadfast Valor',
      pos: '0% 50%',
      zoom: '100%',
      image: 'emblem of steadfast valor tmp.png',
      start: '2025-01-09 10:00:00',
      end: '2025-01-20 03:59:59',
      color: '#c8d4eb',
      url: 'https://www.hoyolab.com/article/35949127',
      showOnHome: true,
    },
    {
      name: 'Realm of Tempered Valor',
      pos: '0% 30%',
      image: 'Realm of Tempered Valor tmp.png',
      start: '2025-02-24 10:00:00',
      end: '2025-03-10 03:59:59',
      color: '#e6e4c4',
      zoom: '100%',
      url: 'https://www.hoyolab.com/article/36971146',
      showOnHome: true,
    },
  ],
  [
    {
      name: "Shuyu's Baffling Beetle Battle Bowl",
      pos: '0% 30%',
      zoom: '100%',
      image: 'shuyus baffling beetle battle bowl tmp.png',
      start: '2025-01-03 10:00:00',
      end: '2025-01-13 03:59:59',
      color: '#d8c43e',
      url: 'https://www.hoyolab.com/article/35949127',
      showOnHome: true,
    },
    {
      name: 'On the Trail of Behemoths',
      pos: '0% 60%',
      zoom: '100%',
      image: 'on the trail of behemoths tmp.png',
      start: '2025-01-14 10:00:00',
      end: '2025-01-27 03:59:59',
      color: '#eae1d1',
      url: 'https://www.hoyolab.com/article/35949127',
      showOnHome: true,
    },
    {
      name: 'Overflowing Mastery',
      pos: '0% 50%',
      image: 'overflowing_mastery.jpg',
      start: '2025-02-03 04:00:00',
      end: '2025-02-10 03:59:59',
      color: '#f5debc',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/36388407',
      showOnHome: true,
    },
    {
      name: 'Enchanted Tales of the Mikawa Festival',
      pos: '0% 20%',
      image: 'Enchanted Tales of the Mikawa Festival tmp.png',
      start: '2025-02-14 10:00:00',
      end: '2025-03-03 03:59:59',
      color: '#ffd8ee',
      zoom: '100%',
      url: 'https://www.hoyolab.com/article/36971146',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Memories Flowing With Color',
      pos: '0% 20%',
      image: 'Memories Flowing With Color.png',
      start: '2025-01-01 06:00:00',
      end: '2025-01-21 17:59:59',
      color: '#f6c3b7',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/35948962',
      showOnHome: true,
    },
    {
      name: 'Springtime Charms',
      pos: '30% 20%',
      zoom: '200%',
      image: 'springtime charms tmp.png',
      start: '2025-01-24 10:00:00',
      end: '2025-02-09 03:59:59',
      color: '#ffc297',
      url: 'https://www.hoyolab.com/article/36388407',
      showOnHome: true,
    },
    {
      name: 'Tapirus Somniator Chapter',
      pos: '0% 20%',
      image: 'Tapirus Somniator Chapter.png',
      start: '2025-02-12 06:00:00',
      end: '2025-03-04 17:59:59',
      color: '#13a5ff',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/36971026',
      showOnHome: true,
      timezoneDependent: true,
    },
  ],
  [
    {
      name: 'As the Blazing Sun',
      pos: '0% 20%',
      image: 'As the Blazing Sun.png',
      start: '2025-01-01 06:00:00',
      end: '2025-01-21 17:59:59',
      color: '#ffbebe',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/35948965',
      showOnHome: true,
    },
    {
      name: 'May Fortune Find You - Daily Login',
      pos: '0% 20%',
      image: 'daily_login.jpg',
      start: '2025-01-22 04:00:00',
      end: '2025-02-11 14:59:59',
      color: '#F18B53',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/35949127',
      showOnHome: true,
    },
    {
      name: "Travelers' Tales: Anthology Chapter",
      pos: '0% 40%',
      image: 'Travelers Tales Anthology Chapter.png',
      start: '2025-02-12 06:00:00',
      end: '2025-03-25 14:59:59',
      color: '#f0e873',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/36971022',
      showOnHome: true,
      timezoneDependent: true,
    },
  ],
  [
    {
      name: 'Ancient Flame Ablaze - Mavuika Banner',
      pos: '30% 18%',
      zoom: '200%',
      image: 'Ancient Flame Ablaze 1.png',
      start: '2025-01-01 06:00:00',
      end: '2025-01-21 17:59:00',
      color: '#ff6b37',
      url: 'https://www.hoyolab.com/article/35948976',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: "The Hearth's Ashen Shadow - Arlecchino Banner",
      pos: '30% 10%',
      zoom: '200%',
      image: 'The Hearths Ashen Shadow 2.png',
      start: '2025-01-21 18:00:00',
      end: '2025-02-11 14:59:00',
      color: '#f8f8f8',
      url: 'https://www.hoyolab.com/article/35948984',
      showOnHome: true,
    },
    {
      name: "Dawn's Drifting Reverie - Yumemizuki Mizuki Banner",
      pos: '30% 15%',
      zoom: '200%',
      image: 'Dawns Drifting Reverie 1.png',
      start: '2025-02-12 06:00:00',
      end: '2025-03-04 17:59:00',
      color: '#d8f0ff',
      url: 'https://www.hoyolab.com/article/36971021',
      showOnHome: true,
      timezoneDependent: true,
    },
  ],
  [
    {
      name: "Starry Night's Whispers - Citlali Banner",
      pos: '30% 18%',
      zoom: '200%',
      image: 'Starry Nights Whispers 1.png',
      start: '2025-01-01 06:00:00',
      end: '2025-01-21 17:59:00',
      color: '#efcbe5',
      url: 'https://www.hoyolab.com/article/35948976',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Illuminating Lightning - Clorinde Banner',
      pos: '30% 18%',
      zoom: '200%',
      image: 'Illuminating Lightning 2.png',
      start: '2025-01-21 18:00:00',
      end: '2025-02-11 14:59:00',
      color: '#6968ff',
      url: 'https://www.hoyolab.com/article/35948984',
      showOnHome: true,
    },
    {
      name: 'Romaritime Meluserenity - Sigewinne Banner',
      pos: '30% 22%',
      zoom: '200%',
      image: 'Romaritime Meluserenity 2.png',
      start: '2025-02-12 06:00:00',
      end: '2025-03-04 17:59:00',
      color: '#fadbf8',
      url: 'https://www.hoyolab.com/article/36971021',
      showOnHome: true,
      timezoneDependent: true,
    },
  ],
  [
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 40%',
      zoom: '200%',
      image: 'Epitome Invocation 75.png',
      start: '2025-01-01 06:00:00',
      end: '2025-01-21 17:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/35948976',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 25%',
      zoom: '200%',
      image: 'Epitome Invocation 76.png',
      start: '2025-01-21 18:00:00',
      end: '2025-02-11 14:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/35948984',
      showOnHome: true,
    },
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 45%',
      zoom: '200%',
      image: 'Epitome Invocation 77.png',
      start: '2025-02-12 06:00:00',
      end: '2025-03-04 17:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/36971021',
      showOnHome: true,
      timezoneDependent: true,
    },
  ],
  [
    {
      name: 'Remembrance of Jade and Stone - Chronicled Banner',
      pos: '50% 15%',
      zoom: '200%',
      image: 'Remembrance of Jade and Stone 1.png',
      start: '2025-01-21 18:00:00',
      end: '2025-02-11 14:59:00',
      color: '#eee8e1',
      url: 'https://www.hoyolab.com/article/35948984',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2024-12-16 04:00:00',
      end: '2025-01-16 04:00:00',
      color: '#4299E1',
      description:
        "All party members' Shield Strength is increased by 20%. While the active character is protected by a Shield, when they deal DMG to opponents, their attacks will unleash a shockwave at the opponents' position that deals True DMG. This can trigger once every 5s.",
    },
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2025-01-16 04:00:00',
      end: '2025-02-16 04:00:00',
      color: '#4299E1',
      description:
        "After a party member triggers a Pyro-related reaction, a shockwave will be unleashed at the character's position, dealing True DMG to nearby opponents. This effect can be triggered once every 3s.",
    },
  ],
  [
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2025-01-01 04:00:00',
      end: '2025-02-01 04:00:00',
      color: '#777df3',
      description:
        'Required Elemental Types: Pyro, Cryo, and Geo. Opening Characters: Diluc, Bennett, Ganyu, Kaeya, Navia, and Noelle',
    },
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2025-02-01 04:00:00',
      end: '2025-03-01 04:00:00',
      color: '#777df3',
      description:
        'Required Elemental Types: Pyro, Hydro, and Anemo. Opening Characters: Hu Tao, Gaming, Mona, Xingqiu, Venti, and Shikanoin Heizou',
    },
  ],
  [
    {
      name: 'Battle Pass - Soulblaze',
      pos: '0% 45%',
      image: 'soulblaze.png',
      start: '2025-01-01 06:00:00',
      end: '2025-02-10 03:59:59',
      color: '#ffb95e',
      url: 'https://www.hoyolab.com/article/35949127',
      timezoneDependent: true,
    },
    {
      name: 'Battle Pass - Wondrous Reverie',
      pos: '0% 45%',
      image: 'Wondrous Reverie.jpg',
      start: '2025-02-12 06:00:00',
      end: '2025-03-24 03:59:59',
      color: '#ffb95e',
      url: 'https://www.hoyolab.com/article/35949127',
      timezoneDependent: true,
    },
  ],
];
